import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import AddIcon from '@material-ui/icons/Search';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import Brightness4 from '@material-ui/icons/Brightness4';
import AllOut from '@material-ui/icons/AllOut';
import Cookies2 from 'react-cookies'
import Cookies from 'universal-cookie';

import {useStyles} from './styles';
import Timerr from './timer';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

const darkThemeTitle = createMuiTheme({
    palette: {
        primary: {
            light: '#a9b7d0',
            main: '#3f4d67',
            dark: '#333f54',
            contrastText: '#ffffff'
        }
    },
    typography: {
        fontFamily: 'Nanum Gothic'
    }
});

const darkTheme = {
    bgColor: '#191b20',
    tableBgColor: "#1e2026",
    chipBgColor: "#2b2f36",
    chip2BgColor: "#2b2f36",
    textColor: "#ffffff",
    borderColor: "2px solid #2a2a2a",
    themeStr: "Light Mode"
}

const lightTheme = {
    bgColor: '#f4f7fa',
    tableBgColor: "#ffffff",
    chipBgColor: "#e2e2e2",
    chip2BgColor: "#e2e2e2",
    textColor: "#2a2a2a",
    borderColor: "2px solid #e2e2e2",
    themeStr: "Dark Mode"
}


const nMode = {
    modeStr: '왼쪽 정렬'
};

const tMode = {
    modeStr: '중앙 정렬'
};


class CoinTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            coinList: [],
            usd: 0,
            usdView: 0,
            bithumbTether: 0,
            bithumbTetherPre: 0,
            upbitTether: 0,
            upbitTetherPre: 0,
            upStatus: "연결중",
            binStatus: "연결중",
            bitStatus: "연결중",
            searchCoin: "",
            coinListDic: {},
            theme: lightTheme,
            mode: nMode,
            themeTitle: darkThemeTitle
        };

        this.addCoin = this
            .addCoin
            .bind(this);
    }

    

    makeCoinListDic() {
        this.setState(state => {
            const coinListDic = {};
            state
                .coinList
                .forEach((element, i) => {
                    coinListDic[element.coin] = i
                });
            return {coinListDic};
        });

        //console.log(this.state.coinListDic);
    }

    updateCoinPrice(coin, element) {
        this.setState(state => {
            const coinList = state.coinList;
            // let tmpElement = coinList[state.coinListDic[coin]] if (tmpElement.coin ===
            // element.coin) {     tmpElement = element; }

            return {coinList};
        });

        //console.log(this.state.coinListDic);
    }

    //trade1 업비트 trade2 바이낸스 trade3 빗썸 pre1 업비트 바이낸스 pre2 빗썸 바이낸스
    addCoin(coin, trade1, trade2, trade3, calcu1, calcu2, calcu3, pre1, pre2) {
        this.setState(prevState => ({
            coinList: [
                ...prevState.coinList, {
                    coin,
                    trade1,
                    trade2,
                    trade3,
                    calcu1,
                    calcu2,
                    calcu3,
                    pre1,
                    pre2
                }
            ]
        }));

        this.makeCoinListDic();
    }

    deleteCoinClick = (coin) => () => {
        console.log(coin);
        this.setState(prevState => ({
            coinList: prevState
                .coinList
                .filter(coinList => coinList.coin !== coin)
        }));
        this.saveCookie();
        this.makeCoinListDic();
    };

    changeTheme = (themeStr) => () => {
        if (themeStr === "Light Mode") {
            this.setState({"theme": lightTheme});
            document.body.style.backgroundColor = lightTheme.bgColor;
        } else {
            this.setState({"theme": darkTheme});
            document.body.style.backgroundColor = darkTheme.bgColor;
        }

        this.saveCookie();
    };

    changeMode = (modeStr) => () => {
        if (modeStr === "중앙 정렬") {
            this.setState({"mode": nMode});
        } else {
            this.setState({"mode": tMode});
        }

        this.saveCookie();
    };

    resetCookie = () => () => {
        document
            .cookie
            .split(";")
            .forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });

        window.location.href = window.location.href;
    };

    componentDidMount() {
        try {
            let ttheme = Cookies2.load('theme');
            if (ttheme === "Light Mode") {
                this.setState({"theme": darkTheme});
                document.body.style.backgroundColor = darkTheme.bgColor;
            }
            let ttheme2 = Cookies2.load('mode');
            if (ttheme2 === "중앙 정렬") {
                this.setState({"mode": tMode});
            }
        } catch  {}

        this.getCoin();
    }

    onUpdate = (val) => {
        this.setState({coinList: val});
    };

    addCoinInput = (event) => {
        if (event.keyCode === 13) {
            let tmpC = this
                .state
                .searchCoin
                .toUpperCase();

            let fail = tmpC in this.state.coinListDic;
            if (!fail) {
                this.addCoin(tmpC, 0, 0, 0, 0, 0, 0, 0, 0, 0);
                this.saveCookie();

                setTimeout(function () {
                    window.location.href = window.location.href;
                }.bind(this), 1500);
            }

        }
    };

    saveCookie() {
        setTimeout(function () {
            const expires = new Date()
            expires.setDate(expires.getDate() + 365);

            const myObjStr = JSON.stringify(this.state.coinList);
            // console.log(myObjStr);

            const cookies = new Cookies();
            const current = new Date();
            const nextYear = new Date();
            nextYear.setFullYear(current.getFullYear() + 10);

            cookies.set('coinList', myObjStr, {
                path: '/',
                expires: nextYear
            });

            cookies.set('theme', this.state.theme.themeStr, {
                path: '/',
                expires: nextYear
            });
            cookies.set('mode', this.state.mode.modeStr, {
                path: '/',
                expires: nextYear
            });
        }.bind(this), 1000);
    }

    getBithumbAll = async () => {
        return fetch(
            'https://api.bithumb.com/public/ticker/ALL_KRW'
        )
            .then(request => request.json())
            .catch(err => {
            })
        }

    getKRWUSD = async () => {
        //https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD
        return fetch(
            'https://crix-api-cdn.upbit.com/v1/forex/recent?codes=FRX.KRWUSD'
        )
            .then(request => request.json())
            .catch(err => {
                alert("환율 정보를 가져올수 없습니다.\n새로고침 합니다.");
                window.location.href = window.location.href;
            })
        }


    initBithumb = async () => {
        const bitthumbAll = await this.getBithumbAll();
        //console.log(bitthumbAll);
        try {
            let jsonn = bitthumbAll.data;
            //console.log(Object.keys(jsonn));
            let tmp = Object.keys(jsonn);
            for (const num in tmp) {
                let symbol = tmp[num];
                if (symbol in this.state.coinListDic) {
                    let price = jsonn[symbol].closing_price;
                    let index = this
                        .state
                        .coinListDic[symbol];
                    let element = this
                        .state
                        .coinList[index];
                    if (element) {
                        //console.log(symbol);
                        if (element.calcu1 !== price) {
                            let priceC = this.numberWithCommas(price);
                            element.trade3 = priceC;
                            element.calcu3 = price;
                            element.pre2 = this.precaculate(element.calcu3, element.calcu2)

                            this.updateCoinPrice(element.coin, element);
                        }
                    }
                }

                if(symbol == "USDT"){
                    let price = jsonn[symbol].closing_price;
                    let priceC = this.numberWithCommas(price);
                    let pre2 = this.precaculate(price, this.state.usd)
                    this.setState({
                        bithumbTether: priceC,
                        bithumbTetherPre: pre2,
                    });
                }
            }

            
        } catch (e1) {
            //console.log(e1);
        }
    }

    getCoin = async () => {
        const usdData = await this.getKRWUSD();
        const krwusd = usdData[0].basePrice;


        this.setState({usd: krwusd});
        this.setState({
            usdView: this.numberWithCommas(krwusd) + " KRW"
        });

        try {
            Cookies2
                .load('coinList')
                .forEach(element => {
                    let tttel = element.coin;
                    this.addCoin(tttel, 0, 0, 0, 0, 0, 0, 0, 0, 0);
                });
        } catch  {
            this.addCoin("BTC", 0, 0, 0, 0, 0, 0, 0, 0, 0);
            this.addCoin("ETH", 0, 0, 0, 0, 0, 0, 0, 0, 0);
            // this.addCoin("XRP", 0, 0, 0, 0, 0, 0, 0, 0, 0);
            // this.addCoin("BCH", 0, 0, 0, 0, 0, 0, 0, 0, 0);
        }

        setTimeout(function () {
            let tmpList = '';
            this
                .state
                .coinList
                .forEach(element => {
                    tmpList += element
                        .coin
                        .toLowerCase() + "usdt@miniTicker/";
                });
            tmpList = tmpList.slice(0, -1);
            //console.log(tmpList);
            const ws = new WebSocket(
                'wss://stream.binance.com/stream?streams=' + tmpList
            );
            this.setState({ws: ws});
            ws.onopen = (e) => {
                //console.log(e);
            };
            ws.onerror = e => {
                console.log(e);
            };
            ws.onclose = e => {
                this.setState({binStatus: "끊김"});
                //console.log(e);
            };
            ws.onmessage = e => {
                //console.log(e);
                try {
                    let jsonn = JSON.parse(e.data)['data'];
                    //console.log(jsonn);
                    const nowTimestamp = Date.now()
                    let timestamp = jsonn['T'];
                    if (true) {
                        let symbol = jsonn['s'].replace("USDT", "");
                        let priceusd = jsonn['c'];
                        let pricekrw = priceusd * this.state.usd;

                        //KRW-BTC
                        if (symbol in this.state.coinListDic) {
                            let index = this
                                .state
                                .coinListDic[symbol];
                            let element = this
                                .state
                                .coinList[index];

                            if (element) {
                                if (element.calcu2 !== pricekrw) {
                                    //let tmp = Math.ceil(priceusd * 100) / 100;

                                    let priceusdC = this.numberWithCommas(priceusd);
                                    element.calcu2 = pricekrw;
                                    element.trade2 = priceusdC;
                                    element.pre1 = this.precaculate(element.calcu1, element.calcu2)
                                    element.pre2 = this.precaculate(element.calcu3, element.calcu2)

                                    this.updateCoinPrice(element.coin, element);
                                    this.setState({binStatus: "정상"});
                                }
                            }
                        }
                    }
                } catch (e1) {
                    //console.log(e1);
                }
            };

            let tmpList2 = '';
            this
                .state
                .coinList
                .forEach(element => {
                    tmpList2 += '"KRW-' + element
                        .coin
                        .toUpperCase() + '",';
                });
            tmpList2 = tmpList2.slice(0, -1);

            const ws2 = new WebSocket('wss://api.upbit.com/websocket/v1');
            this.setState({ws2: ws2});
            ws2.onerror = e => {
                console.log(e);
            };
            ws2.onclose = e => {
                this.setState({upStatus: "끊김"});
                //console.log(e);
            };
            ws2.onopen = (e) => {
                //console.log(e); console.log(tmpList2);
                ws2.send('[{"ticket":"test"},{"type":"ticker","codes":[' + tmpList2 + ',KRW-USDT]}]');
            };
            ws2.onmessage = e => {
                //console.log(e);
                try {
                    const reader = new FileReader();
                    reader.addEventListener('loadend', (e) => {
                        const text = e.srcElement.result;
                        let jsonn = JSON.parse(text);
                        //console.log(jsonn);

                        const nowTimestamp = Date.now()
                        let timestamp = jsonn['timestamp'];
                        if (true) {
                            let symbol = jsonn['code'].replace("KRW-", "");
                            let price = jsonn['trade_price'];

                            //KRW-BTC
                            if (symbol in this.state.coinListDic) {
                                let index = this
                                    .state
                                    .coinListDic[symbol];
                                let element = this
                                    .state
                                    .coinList[index];

                                if (element) {
                                    if (element.calcu1 !== price) {
                                        let priceC = this.numberWithCommas(price);
                                        element.calcu1 = price;
                                        element.trade1 = priceC;
                                        element.pre1 = this.precaculate(element.calcu1, element.calcu2)

                                        this.updateCoinPrice(element.coin, element);
                                        this.setState({upStatus: "정상"});
                                    }
                                }
                            }

                            if(symbol == "USDT"){
                                let priceC = this.numberWithCommas(price);
                                let pre2 = this.precaculate(price, this.state.usd)
                                this.setState({
                                    upbitTether: priceC,
                                    upbitTetherPre: pre2,
                                });
                            }
                        }
                    });
                    reader.readAsText(e.data);

                } catch (e1) {
                    //console.log(e1);
                }
            };

            let tmpList3 = '';
            this
                .state
                .coinList
                .forEach(element => {
                    tmpList3 += '"' + element
                        .coin
                        .toUpperCase() + '_KRW",';
                });
            tmpList3 = tmpList3.slice(0, -1);

            this.initBithumb();
            const ws3 = new WebSocket('wss://pubwss.bithumb.com/pub/ws');
            this.setState({ws3: ws3});
            ws3.onerror = e => {
                console.log(e);
            };
            ws3.onclose = e => {
                this.setState({bitStatus: "끊김"});
                //console.log(e);
            };
            ws3.onopen = (e) => {
                //console.log(e); console.log(tmpList3);
                //ws3.send('{"type":"transaction", "symbols":[' + tmpList3 + ']}');
                ws3.send('{"type":"ticker", "symbols":[' + tmpList3 + ',"USDT_KRW"],"tickTypes":["30M"]}');
            };
            ws3.onmessage = e => {
                //console.log(e);
                try {
                    //console.log(ws3.bufferedAmount);
                    // if(ws3.queueSize() > 1){
                    //     //ws3.flush();
                    // }

                    let jsonn = JSON
                        .parse(e.data)
                        .content;

                    let symbol = jsonn
                        .symbol
                        .replace('_KRW', '');
                    //let price = jsonn.contPrice;
                    let price = jsonn.closePrice;

                    //BTC_KRW
                    if (symbol in this.state.coinListDic) {
                        let index = this
                            .state
                            .coinListDic[symbol];
                        let element = this
                            .state
                            .coinList[index];

                        if (element) {
                            if (element.calcu3 !== price) {
                                let priceC = this.numberWithCommas(price);
                                element.trade3 = priceC;
                                element.calcu3 = price;
                                element.pre2 = this.precaculate(element.calcu3, element.calcu2)

                                this.updateCoinPrice(element.coin, element);
                                this.setState({bitStatus: "정상"});
                            }
                        }
                    }

                    if(symbol == "USDT"){
                        let priceC = this.numberWithCommas(price);
                        let pre2 = this.precaculate(price, this.state.usd)
                        this.setState({
                            bithumbTether: priceC,
                            bithumbTetherPre: pre2,
                        });
                    }
                } catch (e1) {
                    //console.log(e1);
                }
            };
        }.bind(this), 1000);

    }

    numberWithCommas(x) {
        if (x.toString().indexOf(".") >= 0) {
            let tmp = x
                .toString()
                .split(".")
            let tmp1 = tmp[0]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            let tmp2 = tmp[1].replace(/[\.0]0*$/, "")
            return tmp1 + "." + tmp2;
        } else {
            return x
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    precaculate(a, b) {
        if (a === 0 || b === 0) {
            return "---";
        } else {
            return ((a - b) / b * 100).toFixed(3) + "%";
        }
    }

    getLayout(classes) {
        if (this.state.mode.modeStr == "중앙 정렬") {
            return classes.layoutT;
        } else {
            return classes.layout;
        }
        

        
    }

    render() {
        const {classes} = this.props;

        return (
            <div>
                {/* <MuiThemeProvider theme={this.state.themeTitle} className={classes.root}>
                    <AppBar position="static">
                        
                    </AppBar>
                </MuiThemeProvider> */}
                <main 
                    className={this.getLayout(classes)}>
                    <Paper
                        className={classes.paperAppBar}
                        style={{
                            backgroundColor: this.state.theme.tableBgColor
                        }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="menu">
                                <Typography 
                                    variant="h6" 
                                    className={classes.title} 
                                    style={{
                                        color: this.state.theme.textColor
                                    }}>KPRE.KR</Typography>
                            </IconButton>
                            <Typography variant="h6" className={classes.title}></Typography>

                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <AddIcon/>
                                </div>
                                <InputBase
                                    onKeyDown={this.addCoinInput}
                                    onChange={event => {
                                        this.setState({searchCoin: event.target.value});
                                    }}
                                    placeholder="코인 심볼"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}/>
                            </div>
                        </Toolbar>
                    </Paper>

                    
                    <Paper
                        className={classes.paper}
                        style={{
                            backgroundColor: this.state.theme.tableBgColor
                        }}>
                        <TableContainer >
                            <Table>
                                <TableHead>
                                    <TableRow
                                        style={{
                                            "borderBottom" : this.state.theme.borderColor
                                        }}>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}>코인</TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor,
                                                "padding" : 0
                                            }}
                                            align="right"
                                            nowrap="nowrap">
                                                <Chip
                                                    avatar={<Avatar src = "./upbit.svg" />}
                                                    className={classes.chipTable}
                                                    label="업비트"
                                                    style={{
                                                        backgroundColor: "#093687",
                                                        color: "#fff"
                                                    }}/>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor,
                                                "padding" : 0,
                                                "paddingLeft" : 10
                                            }}
                                            align="right"
                                            nowrap="nowrap">
                                                <Chip
                                                    avatar={<Avatar src = "./binance.png" />}
                                                    className={classes.chipTable}
                                                    label="바이낸스"
                                                    style={{
                                                        backgroundColor: "#f0b90b",
                                                        color: "#fff"
                                                    }}/>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            align="right"
                                            nowrap="nowrap">프리미엄</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this
                                            .state
                                            .coinList
                                            .map((data, index) => {
                                                return (
                                                    <TableRow
                                                        key={data.coin}
                                                        style={{
                                                            "borderBottom" : this.state.theme.borderColor
                                                        }}>
                                                        <TableCell
                                                            style={{
                                                                color: this.state.theme.textColor
                                                            }}
                                                            component="th"
                                                            scope="row">
                                                            <Chip
                                                                onDelete={this.deleteCoinClick(data.coin)}
                                                                size="small"
                                                                label={data.coin}
                                                                style={{
                                                                    backgroundColor: this.state.theme.chip2BgColor,
                                                                    color: this.state.theme.textColor
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: this.state.theme.textColor
                                                            }}
                                                            align="right">{data.trade1}</TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: this.state.theme.textColor
                                                            }}
                                                            align="right">{data.trade2}</TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: this.state.theme.textColor
                                                            }}
                                                            align="right">{data.pre1}</TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    }
                                    <TableRow
                                        style={{
                                            "borderBottom" : this.state.theme.borderColor
                                        }}>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            component="th"
                                            scope="row">
                                            <Chip
                                                size="small"
                                                label="USDT"
                                                style={{
                                                    backgroundColor: this.state.theme.chip2BgColor,
                                                    color: this.state.theme.textColor
                                                }}/>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            align="right">{this.numberWithCommas(this.state.bithumbTether)}</TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            align="right">{this.numberWithCommas(this.state.usd)}</TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            align="right">{this.state.bithumbTetherPre}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    
                    <Paper
                        className={classes.paper}
                        style={{
                            backgroundColor: this.state.theme.tableBgColor
                        }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow
                                        style={{
                                            "borderBottom" : this.state.theme.borderColor
                                        }}>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}>코인</TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor,
                                                "padding" : 0
                                            }}
                                            align="right"
                                            nowrap="nowrap">
                                                <Chip
                                                    avatar={<Avatar src = "./bithumb2.png" />}
                                                    className={classes.chipTable}
                                                    label="빗썸"
                                                    style={{
                                                        backgroundColor: "#f47320",
                                                        color: "#fff"
                                                    }}/>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor,
                                                "padding" : 0,
                                                "paddingLeft" : 10
                                            }}
                                            align="right"
                                            nowrap="nowrap">
                                                <Chip
                                                    avatar={<Avatar src = "./binance.png" />}
                                                    className={classes.chipTable}
                                                    label="바이낸스"
                                                    style={{
                                                        backgroundColor: "#f3ba2f",
                                                        color: "#fff"
                                                    }}/>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            align="right">프리미엄</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this
                                            .state
                                            .coinList
                                            .map((data, index) => {
                                                return (
                                                    <TableRow
                                                        key={data.coin}
                                                        style={{
                                                            "borderBottom" : this.state.theme.borderColor
                                                        }}>
                                                        <TableCell
                                                            style={{
                                                                color: this.state.theme.textColor
                                                            }}
                                                            component="th"
                                                            scope="row">
                                                            <Chip
                                                                onDelete={this.deleteCoinClick(data.coin)}
                                                                size="small"
                                                                label={data.coin}
                                                                style={{
                                                                    backgroundColor: this.state.theme.chip2BgColor,
                                                                    color: this.state.theme.textColor
                                                                }}/>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: this.state.theme.textColor
                                                            }}
                                                            align="right">{data.trade3}</TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: this.state.theme.textColor
                                                            }}
                                                            align="right">{data.trade2}</TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: this.state.theme.textColor
                                                            }}
                                                            align="right">{data.pre2}</TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    }
                                    <TableRow
                                        style={{
                                            "borderBottom" : this.state.theme.borderColor
                                        }}>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            component="th"
                                            scope="row">
                                            <Chip
                                                size="small"
                                                label="USDT"
                                                style={{
                                                    backgroundColor: this.state.theme.chip2BgColor,
                                                    color: this.state.theme.textColor
                                                }}/>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            align="right">{this.numberWithCommas(this.state.upbitTether)}</TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            align="right">{this.numberWithCommas(this.state.usd)}</TableCell>
                                        <TableCell
                                            style={{
                                                color: this.state.theme.textColor
                                            }}
                                            align="right">{this.state.upbitTetherPre}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                
                    <Chip
                        avatar={<Brightness4 />}
                        className={classes.chip}
                        onClick={this.changeTheme(this.state.theme.themeStr)}
                        label={this.state.theme.themeStr}
                        style={{
                            backgroundColor: this.state.theme.chip2BgColor,
                            color: this.state.theme.textColor
                        }}/>
                    <Chip
                        avatar={<AllOut />}
                        className={classes.chip}
                        onClick={this.changeMode(this.state.mode.modeStr)}
                        label={this.state.mode.modeStr}
                        style={{
                            backgroundColor: this.state.theme.chip2BgColor,
                            color: this.state.theme.textColor
                        }}/>
                    <Chip
                        className={classes.chip}
                        avatar={<MonetizationOn />}
                        label={this.state.usdView}
                        style={{
                            backgroundColor: this.state.theme.chipBgColor,
                            color: this.state.theme.textColor
                        }}/>
                        
                    <div></div>

                    <Chip
                        className={classes.chip}
                        avatar={<Avatar src = "./upbit.svg" />}
                        label={this.state.upStatus}
                        style={{
                            backgroundColor: this.state.theme.chipBgColor,
                            color: this.state.theme.textColor
                        }}/>
                    <Chip
                        className={classes.chip}
                        avatar={<Avatar src = "./bithumb.png" />}
                        label={this.state.bitStatus}
                        style={{
                            backgroundColor: this.state.theme.chipBgColor,
                            color: this.state.theme.textColor
                        }}/>
                    <Chip
                        className={classes.chip}
                        avatar={<Avatar src = "./binance.png" />}
                        label={this.state.binStatus}
                        style={{
                            backgroundColor: this.state.theme.chipBgColor,
                            color: this.state.theme.textColor
                        }}/>
                    
                    {/* <Chip
                        className={classes.chip}
                        label="&nbsp;제휴문의 : notepad9798@gmail.com&nbsp;"
                        style={{
                            backgroundColor: this.state.theme.chip2BgColor,
                            color: this.state.theme.textColor
                        }}/> { <Chip
                        className={classes.chip}
                        onClick={this.resetCookie()}
                        label="Reset"
                        style={{
                            backgroundColor: this.state.theme.chip2BgColor,
                            color: this.state.theme.textColor
                        }} /> 
                    } */}
                    <Timerr/>
                </main>
                <div style={{
                        height: "1000"
                    }}></div>
            </div>
        );
    }
}

export default withStyles(useStyles)(CoinTable);